/* eslint-disable */
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/good',
  name: 'good',
  meta: { title: '商品管理' },
  redirect: '/good/list',
  component: Main,
  children: [
    {
      path: '/good/list',
      name: 'good-list',
      meta: {
        title: '商品管理',
        parentPath: '/good',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/good/list',
      children: [
        {
          path: '/good/list',
          name: 'good_list',
          meta: {
            title: '商品列表',
            parentPath: '/good',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/good/good-list.vue')
        },
        {
          path: '/good/category',
          name: 'good_category',
          meta: {
            title: '商品类型',
            parentPath: '/good',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/good/good-category.vue')
        },
        {
          path: '/good/edit',
          name: 'good_edit',
          meta: {
            hidden: true,
            title: '商品信息',
            parentPath: '/good',
            prevPath: '/good/list',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/good/good-edit.vue')
        },
      ]
    }
  ]
}]

export default routes
