/* eslint-disable */
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/order',
  name: 'order',
  meta: { title: '订单管理' },
  redirect: '/order/list',
  component: Main,
  children: [
    {
      path: '/order/list',
      name: 'order-list',
      meta: {
        title: '订单管理',
        parentPath: '/order',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/order/list',
      children: [
        {
          path: '/order/list',
          name: 'order_list',
          meta: {
            title: '订单列表',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order-list.vue')
        },
        {
          path: '/order/num',
          name: 'order_num',
          meta: {
            title: '预约管理',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order-num.vue')
        },
      ]
    }
  ]
}]

export default routes
