import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Secondary from '@/layout/secondary.vue'
import home from './new-modules/home'
// import shop from './modules/shop'
// import setting from './modules/setting'
import order from './new-modules/order';
import activity from './new-modules/activity';
import good from './new-modules/good';
import staff from './new-modules/staff';
import user from './new-modules/user';
import system from './new-modules/system';
Vue.use(VueRouter)

export const asyncRoutes: Array<RouteConfig> = [
    // ...index,  //首页
    // ...shop,   //店铺
    // ...setting,   //设置
    ...home,
    ...order,
    ...good,
    ...activity,
    ...staff,
    ...user,
    ...system
]

const constantRoutes: Array<RouteConfig> = [
    {
        path: '*',
        redirect: '/order/list'
    },
    {
        path: '/account',
        name: 'account',
        component: Secondary,
        redirect: '/account/login',
        children: [
            {
                path: '/account/login',
                name: 'login',
                meta: {
                    title: '登录',
                    parentPath: '/account'
                },
                component: () => import('@/views/account/login.vue')
            }
        ]
    },
    {
        path: '/error',
        name: 'error',
        component: Secondary,
        redirect: '/error/404',
        children: [
            {
                path: '/error/404',
                name: 'error_404',
                meta: {
                    title: '404',
                    parentPath: '/error'
                },
                component: () => import('@/views/error/404.vue')
            }, {
                path: '/error/500',
                name: 'error_500',
                meta: {
                    title: '500',
                    parentPath: '/error'
                },
                component: () => import('@/views/error/500.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [...asyncRoutes, ...constantRoutes]
})

export default router
