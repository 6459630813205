/* eslint-disable */
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/user',
  name: 'user',
  meta: { title: '用户管理' },
  redirect: '/user/list',
  component: Main,
  children: [
    {
      path: '/user/list',
      name: 'user-list',
      meta: {
        title: '用户管理',
        parentPath: '/user',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/user/list',
      children: [
        {
          path: '/user/list',
          name: 'user_lists',
          meta: {
            title: '用户列表',
            parentPath: '/user',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/user/user-list.vue')
        }

        // {
        //   path: '/user/lists_detail',
        //   name: 'lists_detail',
        //   meta: {
        //     hidden: true,
        //     title: '商城信息',
        //     parentPath: '/user',
        //     prevPath: '/user/lists',
        //     icon: 'icon_dianpu_home',
        //     permission: ['view']
        //   },
        //   component: () => import('@/views/user/user/lists_edit.vue')
        // },
      ]
    }
  ]
}]

export default routes
