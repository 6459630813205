/* eslint-disable */
// 开发环境域名

const host_development = 'https://test.yesmadam.com.cn'

export default {
// 版本
  version: '1.6.2',
  baseURL: host_development,
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
