import request from '@/plugins/axios'

// 订单列表
export const getOrderList = (params: any) => request.post('/admin/order/getOrderList', params)

// 订单详情
export const getOrderInfo = (params: any) => request.post('/admin/order/getOrderInfo', params)

// 订单退款
export const orderRefund = (params: any) => request.post('/admin/order/refund', params)

// 服务类型列表
export const getServerTypeList = (params: any) => request.post('/admin/goods/getServerTypeList', params)

// 添加服务类型
export const addServerType = (params: any) => request.post('/admin/goods/addServerType', params)

// 修改服务类型
export const editServerType = (params: any) => request.post('/admin/goods/editServerType', params)

// 删除服务类型
export const delServerType = (params: any) => request.post('/admin/goods/delServerType', params)

// 商品列表
export const getGoodsList = (params: any) => request.post('/admin/goods/getGoodsList', params)

// 商品详情
export const getGoodsInfo = (params: any) => request.post('/admin/goods/getGoodsInfo', params)

// 编辑商品
export const editGoods = (params: any) => request.post('/admin/goods/editGoods', params)

// 添加商品
export const addGoods = (params: any) => request.post('/admin/goods/addGoods', params)

// 删除商品
export const delGoods = (params: any) => request.post('/admin/goods/delGoods', params)

// 上下架商品
export const offGoods = (params: any) => request.post('/admin/goods/offGoods', params)

// 团购列表
export const getTeamGoodsList = (params: any) => request.post('/admin/teamGoods/getTeamGoodsList', params)

// 团购详情
export const getTeamGoodsInfo = (params: any) => request.post('/admin/teamGoods/getTeamGoodsInfo', params)

// 添加团购
export const addTeamGoods = (params: any) => request.post('/admin/teamGoods/addTeamGoods', params)

// 编辑团购
export const editTeamGoods = (params: any) => request.post('/admin/teamGoods/editTeamGoods', params)

// 删除团购
export const delTeamGoods = (params: any) => request.post('/admin/teamGoods/delTeamGoods', params)

// 上下架团购
export const offTeamGoods = (params: any) => request.post('/admin/teamGoods/offTeamGoods', params)

// 区域爆破商品列表
export const getAreaGoodsList = (params: any) => request.post('/admin/areaGoods/getAreaGoodsList', params)

// 区域爆破商品详情
export const getAreaGoodsInfo = (params: any) => request.post('/admin/areaGoods/getAreaGoodsInfo', params)

// 添加区域爆破商品
export const addAreaGoods = (params: any) => request.post('/admin/areaGoods/addAreaGoods', params)

// 编辑区域爆破商品
export const editAreaGoods = (params: any) => request.post('/admin/areaGoods/editAreaGoods', params)

// 删除区域爆破商品
export const delAreaGoods = (params: any) => request.post('/admin/areaGoods/delAreaGoods', params)

// 上下架区域爆破商品
export const offAreaGoods = (params: any) => request.post('/admin/areaGoods/offAreaGoods', params)

// 管理员列表
export const getAdminList = (params: any) => request.post('/admin/admin/getAdminList', params)

// 添加管理员
export const addAdmin = (params: any) => request.post('/admin/admin/addAdmin', params)

// 编辑管理员
export const editAdmin = (params: any) => request.post('/admin/admin/editAdmin', params)

// 编辑管理员
export const delAdmin = (params: any) => request.post('/admin/admin/delAdmin', params)

// 获取管理员
export const getAdminInfo = (params: any) => request.post('/admin/admin/getAdminInfo', params)

// 员工列表
export const getStaffList = (params: any) => request.post('/admin/staff/getStaffList', params)

// 新增员工
export const addStaff = (params: any) => request.post('/admin/staff/addStaff', params)

// 编辑员工
export const editStaff = (params: any) => request.post('/admin/staff/editStaff', params)

// 删除员工
export const delStaff = (params: any) => request.post('/admin/staff/delStaff', params)

// 获取员工信息
export const getStaffInfo = (params: any) => request.post('/admin/staff/getStaffInfo', params)

// 禁用员工
export const forbidStaff = (params: any) => request.post('/admin/staff/forbidStaff', params)

// 审核员工
export const checkStaff = (params: any) => request.post('/admin/staff/checkStaff', params)

// 用户列表
export const getUserList = (params: any) => request.post('/admin/user/getUserList', params)

// 设置用户状态
export const setUserStatus = (params: any) => request.post('/admin/user/setUserStatus', params)

// 设置用户标签
export const saveUserFlag = (params: any) => request.post('/admin/user/saveUserFlag', params)

// 预约列表
export const getBookList = (params: any) => request.post('/admin/book/getBookList', params)

// 预约详情
export const getBookInfo = (params: any) => request.post('/admin/book/getBookInfo', params)

// 完成预约
export const finishBook = (params: any) => request.post('/admin/book/finishBook', params)

// 预约指派阿姨
export const selectStaff = (params: any) => request.post('/admin/book/selectStaff', params)

// 获取地区
export const getAreaList = (params: any) => request.post('/admin/common/getAreaList', params)

// 获取地区
export const getStaffArray = () => request.post('/admin/common/getStaffArray')

// 优惠券列表
export const getCouponList = (params: any) => request.post('/admin/coupon/getCouponList', params)

// 优惠券详情
export const getCouponInfo = (params: any) => request.post('/admin/coupon/getCouponInfo', params)

// 添加优惠券
export const addCoupon = (params: any) => request.post('/admin/coupon/addCoupon', params)

// 编辑优惠券
export const editCoupon = (params: any) => request.post('/admin/coupon/editCoupon', params)

// 删除优惠券
export const delCoupon = (params: any) => request.post('/admin/coupon/delCoupon', params)

// 删除优惠券
export const sendUserCoupon = (params: any) => request.post('/admin/coupon/sendUserCoupon', params)

// 积分设置
export const setPointConfig = (params: any) => request.post('/admin/point/setPointConfig', params)

// 获取积分设置
export const getPointConfig = () => request.post('/admin/point/getPointConfig')

// 获取积分设置
export const visitUser = (params: any) => request.post('/admin/book/visitUser', params)

//////角色管理

// 获取积分设置
export const getRoleList = (params: any) => request.post('/admin/role/getRoleList', params)

export const addRole = (params: any) => request.post('/admin/role/addRole', params)

export const editRole = (params: any) => request.post('/admin/role/editRole', params)

export const delRole = (params: any) => request.post('/admin/role/delRole', params)

export const getPerms = (params: any) => request.post('/admin/role/getPerms', params)

export const allotPerms = (params: any) => request.post('/admin/role/allotPerms', params)

export const writerOrderList = (params: any) => request.get('/admin/order/writerOrderList', params)

export const writerBookList = (params: any) => request.post('/admin/book/writerBookList', params)

/// 充值赠送余额
export const getChargeConfigList = (params: any) => request.post('/admin/charge/getChargeConfigList', params)

export const editChargeConfigInfo = (params: any) => request.post('/admin/charge/editChargeConfigInfo', params)

export const getChargeList = (params: any) => request.post('/admin/charge/getChargeList', params)

/// 首页数据统计
export const getOrderPayCount = (params: any) => request.post('/admin/count/getOrderPayCount', params)

export const getRefundApplyOrderList = (params: any) => request.post('/admin/count/getRefundApplyOrderList', params)

export const getBookAllotStaffList = (params: any) => request.post('/admin/count/getBookAllotStaffList', params)

export const getStaffCheckList = (params: any) => request.post('/admin/count/getStaffCheckList', params)

export const resetStaffPassword = (params: any) => request.post('/admin/staff/resetStaffPassword', params)

export const getBookWorkList = (params: any) => request.post('/admin/book/getBookWorkList', params)

export const getUserPointRecordList = (params: any) => request.post('/admin/user/getUserPointRecordList', params)

export const getUserBalanceRecordList = (params: any) => request.post('/admin/user/getUserBalanceRecordList', params)

export const handleComplain = (params: any) => request.post('/admin/book/handleComplain', params)

export const changeMyPassword = (params: any) => request.post('/admin/auth/changeMyPassword', params)

export const getGoodsArray = (params: any) => request.post('/admin/common/getGoodsArray', params)
