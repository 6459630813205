/* eslint-disable */
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/',
  name: 'index',
  meta: { title: '数据统计' },
  redirect: '/index',
  component: Main,
  children: [{
      path: '/index',
      name: 'index',
      meta: {
          hidden: true,
          title: '数据统计',
          parentPath: '/',
          permission: ['view']
      },
      component: () => import('@/views/home/index.vue'),
  },
  ],
}]

export default routes
