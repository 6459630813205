/* eslint-disable */
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/staff',
  name: 'staff',
  meta: { title: '员工管理' },
  redirect: '/staff/list',
  component: Main,
  children: [
    {
      path: '/staff/list',
      name: 'staff-list',
      meta: {
        title: '员工管理',
        parentPath: '/staff',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/staff/list',
      children: [
        {
          path: '/staff/list',
          name: 'staff_lists',
          meta: {
            title: '员工列表',
            parentPath: '/staff',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/staff/staff-list.vue')
        },
        {
          path: '/staff/shift-list',
          name: 'staff-shift-list',
          meta: {
            title: '排班列表',
            parentPath: '/staff',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/staff/shift-list.vue')
        },
        {
          path: '/staff/edit',
          name: 'staff_edit',
          meta: {
            hidden: true,
            title: '员工信息',
            parentPath: '/staff',
            prevPath: '/staff/list',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/staff/staff-edit.vue')
        },
      ]
    }
  ]
}]

export default routes
