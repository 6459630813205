/* eslint-disable */
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/system',
  name: 'system',
  meta: { title: '系统设置' },
  redirect: '/system/list',
  component: Main,
  children: [
    {
      path: '/system/list',
      name: 'system-list',
      meta: {
        title: '系统设置',
        parentPath: '/system',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/system/list',
      children: [
        {
          path: '/system/list',
          name: 'system_lists',
          meta: {
            title: '账号列表',
            parentPath: '/system',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/system/admin-list.vue')
        },

        {
          path: '/system/role-list',
          name: 'system-role-list',
          meta: {
            title: '角色列表',
            parentPath: '/system',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/system/role-list.vue')
        },
        {
          path: '/system/edit',
          name: 'system_edit',
          meta: {
            hidden: true,
            title: '管理员信息',
            parentPath: '/system',
            prevPath: '/system/list',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/system/admin-edit.vue')
        },
      ]
    }
  ]
}]

export default routes
