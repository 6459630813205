/* eslint-disable */
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/activity',
  name: 'activity',
  meta: { title: '促销管理' },
  redirect: '/activity/group-list',
  component: Main,
  children: [
    {
      path: '/activity/group-list',
      name: 'activity-group-list',
      meta: {
        title: '促销管理',
        parentPath: '/activity',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/activity/group-list',
      children: [
        {
          path: '/activity/group-list',
          name: 'activity_group-list',
          meta: {
            title: '团购管理',
            parentPath: '/activity',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/group-list.vue')
        },
        {
          path: '/activity/area-list',
          name: 'activity_area-list',
          meta: {
            title: '区域爆破',
            parentPath: '/activity',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/area-list.vue')
        },
        {
          path: '/activity/point',
          name: 'activity-point',
          meta: {
            title: '积分设置',
            parentPath: '/activity',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/point-edit.vue')
        },
        {
          path: '/activity/coupon-list',
          name: 'activity-coupon-list',
          meta: {
            title: '优惠券',
            parentPath: '/activity',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/coupon-list.vue')
        },
        {
          path: '/activity/top-up',
          name: 'activity-top-up',
          meta: {
            title: '充值优惠',
            parentPath: '/activity',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/top-up.vue')
        },

        {
          path: '/activity/top-up-his',
          name: 'activity-top-up-his',
          meta: {
            title: '充值记录',
            parentPath: '/activity',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/top-up-his.vue')
        },
        {
          path: '/activity/area-edit',
          name: 'area-edit',
          meta: {
            hidden: true,
            title: '区域爆破信息',
            parentPath: '/activity',
            prevPath: '/activity/area-list',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/area-edit.vue')
        },

        {
          path: '/activity/group-edit',
          name: 'group-list',
          meta: {
            hidden: true,
            title: '团购活动信息',
            parentPath: '/activity',
            prevPath: '/activity/group-list',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/activity/group-edit.vue')
        },
      ]
    }
  ]
}]

export default routes
